import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getCompany, getMyAccount } from 'services/user';

import getMenuData from '../../services/menu';
import integrationsActions from '../integrations/actions';
import userActions from '../user/actions';
import actions from './actions';

export function* GET_DATA() {
  const accountResponse = yield call(getMyAccount);

  if (accountResponse) {
    const { ID, Email, Profile = {}, CompanyUsers = [] } = accountResponse;
    yield put({
      type: userActions.SET_USER,
      payload: {
        id: ID,
        name: Profile ? `${Profile.FirstName} ${Profile.LastName}` : '',
        email: Email,
        role: 'admin',
        authenticated: true,
        Profile,
        CompanyUsers,
      },
    });
    if (CompanyUsers && CompanyUsers.length) {
      yield put({
        type: userActions.LOAD_CURRENT_COMPANY,
        payload: {
          id: CompanyUsers[0].CompanyID,
        },
      });
      yield put({
        type: integrationsActions.INIT,
        companyId: CompanyUsers[0].CompanyID,
      });
    }

    let companyResponse = null;
    if (CompanyUsers?.[0]?.CompanyID) {
      companyResponse = yield call(getCompany, CompanyUsers[0].CompanyID);
      if (companyResponse) {
        yield put({
          type: userActions.SET_COMPANY,
          payload: {
            Company: companyResponse,
          },
        });
      }

      const roles = CompanyUsers[0].Roles;
      const menuLeftData = yield call(getMenuData, roles, companyResponse.Settings);

      yield put({
        type: 'menu/SET_STATE',
        payload: {
          menuLeftData,
        },
      });
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    GET_DATA(), // run once on app load to fetch menu data
  ]);
}
