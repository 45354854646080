import { call, put, select } from 'redux-saga/effects';
import { setFileList, setState } from '../actions';
import { getCompanyLibrary } from '../selectors';
import api from '../../../../../../services/api';
import { TablePaginationConfig } from 'antd';
import { CompanyLibraryState } from '../reducers';
import { updateRelations } from './uploadFileWorker';

export function* loadFileListWorker() {
  try {
    yield put(setState({ isLoadingList: true }));
    const { pagination, fileList, filters } = (yield select(
      getCompanyLibrary,
    )) as CompanyLibraryState;
    const queryParams = {
      limit: pagination.pageSize || 50,
      page: pagination.current || 1,
    };

    queryParams['file_name'] = filters.fileName;
    queryParams['related_entity_type_list'] = filters.relatedEntityTypes;
    queryParams['related_entity_id_list'] = filters.relatedEntities.map(e => e.ID);

    const {
      data: { Data, Total },
    } = yield call(api.get, `/v1/company-library/list`, {
      params: queryParams,
    });

    const loadMoreAvailable = Total != 0 && fileList.length + Data.length < Total;
    if (queryParams.page === 1) {
      const updatedFileList = [...Data];
      yield put(setFileList(updatedFileList, loadMoreAvailable));
    } else {
      const updatedFileList = [...fileList, ...Data];
      yield put(setFileList(updatedFileList, loadMoreAvailable));
    }

    const newPagination: TablePaginationConfig = {
      total: Total,
      current: pagination.current,
      pageSize: pagination.pageSize,
    };
    yield updateRelations()
    yield put(setState({ pagination: newPagination, isLoadingList: false }));
  } catch (err) {
    console.log('Failed to load company library file list', err);
  }
}
