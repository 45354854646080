import { HandleItemDoubleClickAction, scheduleActions } from '../../actions';
import dutyTimeModalActions from 'redux/pages/crew-scheduling-v3/modals/DutyTimeEntryModal/actions';
import { call, put } from 'redux-saga/effects';
import { CrewTimelineEntryType } from '../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { DutyScheduleEntry } from 'redux/pages/crew-scheduling-v3/scheduleEntries/types/DutyScheduleEntry';
import MODAL_MODE from '../../../../../common/constants';
import {
  setCurrentDutyScheduleEntryAction,
  setCurrentScheduleEntryAction,
} from 'redux/pages/crew-scheduling-v3/actions';
import { openDutyScheduleModalAction } from 'redux/pages/crew-scheduling-v3/modals/DutyScheduleEntryModal/actions';
import { CrewScheduleEntry } from 'redux/pages/crew-scheduling-v3/scheduleEntries/types/CrewScheduleEntry';
import { openCrewScheduleModalAction } from 'redux/pages/crew-scheduling-v3/modals/ScheduleEntryModal/actions';
import {
  fetchAircraftScheduleEntry,
  fetchEntry,
  fetchGroupedCrewScheduleEntry,
} from '../timelineEntries/fetchEntry';
import { PilotContract } from '../../../../../../../common/types/PilotContract';
import { CrewTimelineItem } from '../../../components/Timeline/CrewTimeline/generator';
import { TimelineType } from '../../../types/timeline';
import { AircraftTimelineItem } from '../../../components/Timeline/AircraftTimeline/generator';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { AircraftSchedule } from '../../../../../../../common/types/aircraftSchedule';
import { AircraftContract } from '../../../../../../../common/types/aircraftContract';
import { DutyTimeEntry } from 'redux/pages/crew-scheduling-v3/scheduleEntries/types/DutyTimeEntry';
import { showErrorModal } from '../../../legacy/utils/errHandling';
import { selectFeatureFlagsSaga } from '../../../../../../../redux/common/selectors-saga';
import { selectUserRoleSaga, selectUserSaga } from '../../../../../../../common/saga/selectors';
import { isHideCSEntryDetails, isHideCSEntryDetailsForUser } from '../../../helpers';
import { ContractTimelineItem } from '../../../components/Timeline/ContractTimeline/generator';
import { ContractTimelineEntryType } from '../../../../../../../common/types/timeline/ContractTimelineEntryType';
import { bulkModalEntryActions } from '../../../components/Modals/BulkScheduleModal/redux-saga/actions';
import { clientContractModalActions } from '../../../components/Modals/ClientContractPeriodModal/redux-saga/actions';
import { ClientContractPeriod } from '../../../../../../../common/types/ClientContractPeriod';

export function* handleItemDoubleClickWorker(action: HandleItemDoubleClickAction) {
  const { item, timelineType } = action.payload;

  try {
    yield put(scheduleActions.setTimelineLoading(true));
    if (timelineType === TimelineType.Crew) {
      yield call(handleCrewTimelineDoubleClick, item as CrewTimelineItem);
    }

    if (timelineType === TimelineType.Aircraft) {
      yield call(handleAircraftTimelineDoubleClick, item as AircraftTimelineItem);
    }

    if (timelineType === TimelineType.Contract) {
      yield call(handleContractTimelineDoubleClick, item as ContractTimelineItem);
    }
  } finally {
    yield put(scheduleActions.setTimelineLoading(false));
  }
}

function* handleContractTimelineDoubleClick(item: ContractTimelineItem) {
  try {
    if (item.entryType === ContractTimelineEntryType.AircraftContract) {
      if (item.isNewEntry) {
      } else {
        // todo
      }
      yield put(scheduleActions.setState({ editContractTimelineEntry: item }));
      yield put(
        scheduleActions.setGroupedAircraftContractModalMode({
          mode: item.isNewEntry ? MODAL_MODE.create : MODAL_MODE.update,
          open: true,
        }),
      );
    } else if (item.entryType === ContractTimelineEntryType.PilotSchedule) {
      console.log('clicked item', item);
      if (item.isNewEntry) {
      } else {
        const entry = (yield fetchGroupedCrewScheduleEntry(
          item.entryType,
          item,
          false,
        )) as AircraftSchedule;

        yield put(scheduleActions.setEditAircraftScheduleEntry(entry));

        // todo
      }
      yield put(
        bulkModalEntryActions.setContractModalMode({
          mode: item.isNewEntry ? MODAL_MODE.create : MODAL_MODE.update,
          open: true,
        }),
      );
    } else if (item.entryType === ContractTimelineEntryType.ClientContractPeriod) {
      const entry = (yield fetchEntry(item.entryType, item.id, false)) as ClientContractPeriod;

      yield put(clientContractModalActions.setEditedClientContractPeriod(entry));
      yield put(
        clientContractModalActions.setEditedClientContractID(entry.CompanyClientContractID),
      );
      yield put(
        clientContractModalActions.setClientContractPeriodModalMode({
          mode: MODAL_MODE.update,
          open: true,
        }),
      );
    }
  } catch (err) {
    console.log('Error in handleContractTimelineDoubleClick', err);
  }
}

function* handleAircraftTimelineDoubleClick(item: AircraftTimelineItem) {
  try {
    if (item.entryType === AircraftTimelineEntryType.AircraftSchedule) {
      const featureFlags = yield* selectFeatureFlagsSaga();
      const userRoles = yield* selectUserRoleSaga();

      const hideCSEntryDetails = isHideCSEntryDetails(featureFlags, userRoles);
      if (hideCSEntryDetails) {
        return;
      }

      if (item.isNewEntry) {
        yield put(
          scheduleActions.setEditAircraftScheduleEntry({
            StartTime: item.start_time,
            EndTime: item.end_time,
            AssignedAircraft: item.aircraftEntry.AssignedAircraftIDs,
          }),
        );
      } else {
        const entry = (yield fetchAircraftScheduleEntry(
          item.entryType,
          item,
          false,
        )) as AircraftSchedule;

        yield put(scheduleActions.setEditAircraftScheduleEntry(entry));
      }

      yield put(
        bulkModalEntryActions.setAircraftModalMode({
          mode: item.isNewEntry ? MODAL_MODE.create : MODAL_MODE.update,
          open: true,
        }),
      );
    } else if (item.entryType === AircraftTimelineEntryType.AircraftContract) {
      const entry = (yield fetchEntry(
        item.entryType,
        item.contractEntry.ID,
        false,
      )) as AircraftContract;
      yield put(scheduleActions.setEditAircraftContractEntry(entry));
      yield put(
        scheduleActions.setAircraftContractModalMode({
          mode: item.isNewEntry ? MODAL_MODE.create : MODAL_MODE.update,
          open: true,
        }),
      );
    }
  } finally {
  }
}

function* handleCrewTimelineDoubleClick(item: CrewTimelineItem) {
  try {
    if (item.entryType === CrewTimelineEntryType.DutyTimes) {
      if (item.isNewEntry) {
        const dutyTimeEntry: DutyTimeEntry = {
          // @ts-ignore
          StartTime: item.start_time,
          EndTime: item.end_time,
          UserID: item.pilotID,
        };
        yield put(dutyTimeModalActions.setCurrentDutyTimeEntry(dutyTimeEntry));
      } else {
        if (item.dutyEntry.IsAwaitingForUpdate) {
          return;
        }
        const dutyTimeEntry = (yield fetchEntry(item.entryType, item.id, false)) as DutyTimeEntry;
        yield put(dutyTimeModalActions.setCurrentDutyTimeEntry(dutyTimeEntry));
      }
      yield put(
        dutyTimeModalActions.openModalAction(
          item.isNewEntry ? MODAL_MODE.create : MODAL_MODE.update,
        ),
      );
    } else if (item.entryType === CrewTimelineEntryType.DutySchedule) {
      if (item.isNewEntry) {
        const dutyScheduleEntry: Partial<DutyScheduleEntry> = {
          // @ts-ignore
          StartTime: item.start_time,
          // @ts-ignore
          EndTime: item.end_time,
          UserID: item.pilotID,
        };
        yield put(setCurrentDutyScheduleEntryAction(dutyScheduleEntry));
      } else {
        if (item.dutyEntry.IsAwaitingForUpdate) {
          return;
        }
        const dutyScheduleEntry = (yield fetchEntry(
          item.entryType,
          item.id,
          false,
        )) as DutyScheduleEntry;
        yield put(setCurrentDutyScheduleEntryAction(dutyScheduleEntry));
      }

      yield put(
        openDutyScheduleModalAction(item.isNewEntry ? MODAL_MODE.create : MODAL_MODE.update),
      );
    } else if (item.entryType === CrewTimelineEntryType.CrewSchedule) {
      const featureFlags = yield* selectFeatureFlagsSaga();
      const userRoles = yield* selectUserRoleSaga();
      const user = yield* selectUserSaga();

      const hideCSEntryDetails = isHideCSEntryDetailsForUser(
        featureFlags,
        userRoles,
        user.id,
        item?.crewEntry?.UserID,
      );

      if (hideCSEntryDetails) {
        return;
      }

      if (item.isNewEntry) {
        yield put(
          setCurrentScheduleEntryAction({
            StartTime: item.start_time,
            EndTime: item.end_time,
            UserID: item.pilotID,
          }),
        );
      } else {
        const crewScheduleEntry = (yield fetchEntry(
          item.entryType,
          item.id,
          false,
        )) as CrewScheduleEntry;
        yield put(setCurrentScheduleEntryAction(crewScheduleEntry));
      }

      yield put(
        openCrewScheduleModalAction(item.isNewEntry ? MODAL_MODE.create : MODAL_MODE.update),
      );
    } else if (item.entryType === CrewTimelineEntryType.PilotContract) {
      if (item.isNewEntry) {
        yield put(
          scheduleActions.setPilotContractEntryForModal({
            StartTime: new Date(item.start_time),
            EndTime: new Date(item.end_time),
          }),
        );
      } else {
        const entry = (yield fetchEntry(item.entryType, item.id, false)) as PilotContract;
        yield put(scheduleActions.setPilotContractEntryForModal(entry));
      }

      yield put(
        scheduleActions.setPilotContractModalMode({
          mode: item.isNewEntry ? MODAL_MODE.create : MODAL_MODE.update,
          open: true,
        }),
      );
    }
  } catch (error) {
    showErrorModal('Failed to open modal', error);
    console.error(error);
  } finally {
  }
}
