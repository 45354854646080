import { HandleMenuItemClickAction, scheduleActions } from '../../actions';
import { MenuItemType } from '../../../types/MenuItemTypes';
import { call, put, select } from 'redux-saga/effects';
import { handleDeleteEntry } from './handleDeleteEntry';
import { AircraftTimelineItem } from '../../../components/Timeline/AircraftTimeline/generator';
import { selectUserSaga } from '../../../../../../../common/saga/selectors';
import LocalCache from '../../../../../../local-storage/local-storage';
import { CrewTimelineItem } from '../../../components/Timeline/CrewTimeline/generator';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { ContractTimelineItem } from '../../../components/Timeline/ContractTimeline/generator';
import { CommonInitState } from '../../../../../../../redux/common/reducers';
import { getCommon } from '../../../../../../../redux/selectors';
import { AircraftSelectorType } from '../../../../../../../redux/common/types';
import { ContractTimelineEntryType } from '../../../../../../../common/types/timeline/ContractTimelineEntryType';

export function* handleMenuItemClickWorker(action: HandleMenuItemClickAction) {
  const { item, menuItem, timelineType } = action.payload;

  if (menuItem === MenuItemType.Delete) {
    yield call(handleDeleteEntry, item, timelineType);
  } else if (menuItem === MenuItemType.Edit || menuItem === MenuItemType.View) {
    yield put(scheduleActions.handleTimelineItemDoubleClick(item, timelineType));
  } else if (menuItem === MenuItemType.Cut) {
    yield put(scheduleActions.setCutItem(item as CrewTimelineItem | AircraftTimelineItem));
    yield put(scheduleActions.setCopyItem(null));
  } else if (menuItem === MenuItemType.Copy) {
    yield put(scheduleActions.setCopyItem(item as CrewTimelineItem | AircraftTimelineItem));
    yield put(scheduleActions.setCutItem(null));
  } else if (menuItem === MenuItemType.ShowAssociatedCrew) {
    const user = yield* selectUserSaga();
    const localCache = new LocalCache('crewSchedulingV2', user);
    let assignedUserIDs: string[] = [];
    if (item.entryType === AircraftTimelineEntryType.AircraftSchedule) {
      const entry = (item as AircraftTimelineItem).aircraftEntry;
      assignedUserIDs = entry.AssignedUserIDs;
    } else if (item.entryType === ContractTimelineEntryType.PilotSchedule) {
      const entry = item as ContractTimelineItem;
      if (entry.userID) {
        assignedUserIDs = [entry.userID];
      } else if (entry.userIDs) {
        assignedUserIDs = entry.userIDs;
      }
    }

    yield put(scheduleActions.setSelectedCrewMemberIds(assignedUserIDs));

    localCache.setCached('selectedCrewMemberIds', assignedUserIDs);
  } else if (menuItem === MenuItemType.ShowAssociatedAircraft) {
    let assignedAircraftIDs = [];

    if (item.entryType === ContractTimelineEntryType.AircraftContract) {
      const entry = item as ContractTimelineItem;
      if (entry.aircraftID) {
        assignedAircraftIDs = [entry.aircraftID];
      } else if (entry.aircraftIDs) {
        assignedAircraftIDs = entry.aircraftIDs;
      }
      yield put(scheduleActions.setSelectedAircraftIdentifiers(assignedAircraftIDs));
    } else {
      const aircraftIDs = (item as ContractTimelineItem).aircraftIDs;
      if (aircraftIDs) {
        assignedAircraftIDs = aircraftIDs;
      }
    }
    const commonState: CommonInitState = yield select(getCommon);
    const aircraftTails = commonState.aircraftList
      .filter(({ ID }) => assignedAircraftIDs.includes(ID))
      .map(aircraft => {
        if (commonState.aircraftSelectorType === AircraftSelectorType.Registration) {
          return aircraft.Registration;
        }
        return aircraft.TailNumber;
      });
    yield put(scheduleActions.setSelectedAircraftIdentifiers(aircraftTails));
  }
}
